import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, Loading } from "@haravan/react-components";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { LeftMenu } from "../left-menu";
import { Header } from "../header/header";
import { changeMenu, getMenus, storeMenu } from "../left-menu/left_menu_slice";
import { getUser, signinRedirect } from "../../screens/auth/services/auth_service";
import { selectUser, storeUser } from "../../screens/auth/services/auth_slice";
import { MENU_LEFT, ALL_MENU_LINKS, MENU_MAPPER } from "../left-menu/menu_left";
import { AuthRepository } from "../../repositories/auth_repository";
import { Popup } from "../../components";

import "./layout.css";

const LayoutComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const location = useLocation();
  const menus = useSelector(getMenus);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const specialPath = ["/"];

  useEffect(() => {
    getUser().then((localUser) => {
      if (localUser) {
        !user && dispatch(storeUser(localUser));
        document['user_id'] = localUser?.profile?.sub;
      }
      else signinRedirect();
    });
  }, []);

  useEffect(() => {
    user && AuthRepository.GetFeatures(user?.profile?.sub)
      .then(rs => {
        if (rs && !rs.has_error) {
          let links = rs.data.map(item => item.url.replace('~', ''));
          let newMenus = MENU_LEFT.filter(m => links.includes(MENU_MAPPER[m.link]));
          dispatch(storeMenu([...newMenus]))
          if (rs.data.length == 0) handlePermissionError();
        } else handlePermissionError();
        setLoading(false)
      });
  }, [user]);

  useEffect(() => {
    const { pathname } = location;
    if (specialPath.includes(pathname)) {
      return handleRedirect();
    };
    if (pathname != "/permission-error") {
      const pathItems = pathname.split("/").filter((n) => !!n);
      const menuLinks = menus.map((m) => m.link?.replace("/", ""));
      if (menuLinks?.length) {
        const nonPermissionLinks = ALL_MENU_LINKS.filter((m) => !menuLinks.includes(m));
        if (nonPermissionLinks.includes(pathItems[0]) || !menuLinks.includes(pathItems[0])) return handlePermissionError();
        menuLinks.includes(pathItems[0]) && dispatch(changeMenu(`/${pathItems[0]}`));

        let path = ''
        const newBreadcrumbs = pathItems.map((item, index, arr) => {
          let menuItem = menus.find(menu => menu.link.replace("/", "") === item)
          if (menuItem) {
            path = `${path}${menuItem.link}`
            return {
              name: menuItem.text,
              path
            }
          } else if (index > 0) {
            path = `${path}/${item}`
            return {
              name: item,
              path: index < arr.length - 1 ? path : ''
            }
          }
          return null;
        });
        setBreadcrumbs(newBreadcrumbs);
      }
      // else return handlePermissionError();

    }
  }, [location]);

  const handlePermissionError = () => {
    setBreadcrumbs([]);
    navigate("permission-error");
  };
  const handleRedirect = () => {
    const menuLinks = menus.map((m) => m.link?.replace("/", ""));
    if (menuLinks?.length) {
      navigate(menuLinks[0]);
    }
  };
  return (
    <div className="m-0 p-0 layout-wapper">
      <Header />
      <div className="layout-content">
        <LeftMenu />
        <div className='layout-container'>
          {
            isLoading
              ? <Loading size='thumb' />
              : <div>
                <Breadcrumb listData={breadcrumbs} />
                <Outlet />
              </div>
          }
        </div>
      </div>

      <Popup />
    </div>
  );
};

export default LayoutComponent;
