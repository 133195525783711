import * as ApiHelpers from './api_helper';

const apiRoute = 'payouts';

export const PayoutRepository = {
  GetPayout: id => ApiHelpers.api_call_get(`${apiRoute}/${id}`),
  GetListPayout: (page = 1, pageSize = 20, search = '') => {
    let path = ApiHelpers.GetApiUrl(`${apiRoute}`, { page, pageSize, search });
    return ApiHelpers.api_call_get(path);
  },
  UpdatePayout: (id, data) => ApiHelpers.api_call_put(`${apiRoute}/${id}`, data),
  Export: (model) => {
    return ApiHelpers.api_call_post(`${apiRoute}/export`, model);
  },
  Filter: (model) => {
    return ApiHelpers.api_call_post(`${apiRoute}/filter`, model);
  },
};
