import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { dataChanged } from '../../../../../components/data_save/data_save_slice'
import { StoreRepository } from '../../../../../repositories'
import { toDateString, toDateTimeString, toNumberString } from '../../../../../global/utils/functions'
import { CardDetail, Detail, Link, Status, T } from '../../../../../components'
import { useParams } from 'react-router-dom'
import { Email, Phone } from '../../../../../components'
import * as React from 'react';
import * as HrvComponents from '@haravan/react-components';
import { CardAddressShop, CardDetailInfo, CardPhoneDetailInfo } from '../../../../../components/card_detail/card_detail'

import './index.css'
import { CurrencyTransaction } from '../../../../../components/format/format'

export const ShopInfo = props => {
  const { shopId } = useParams()
  const [storeOrigin, setStoreOrigin] = useState(null)
  const [isLoadingData, setLoadingData] = useState(true)
  const [store, setStore] = useState(null)
  const [activeKeys, setActiveKeys] = useState([])
  const timeNow = new Date().toISOString();

  const dispatch = useDispatch()

  useEffect(() => {
    !storeOrigin &&
      StoreRepository.GetStore(shopId).then(rs => {
        setStoreOrigin(rs?.data)
        setStore(rs?.data)
        setLoadingData(false)
      })
    return () => {
      dispatch(dataChanged({ hasChanged: false, data: null }))
    }
  }, [])

  const renderShopInformation = () => {
    return (
      <div>
        <div><CardDetailInfo name='Tên website' value={store?.name} tilte='Tên shop khi đăng ký'></CardDetailInfo></div>
        <div><CardDetailInfo name='Lần cuối đăng nhập' value={toDateTimeString(store?.lastLoginDate)} tilte='Lần cuối user của shop đăng nhập'></CardDetailInfo></div>
        <div><CardDetailInfo name='Domain' value={<Link to={store?.buyerDomain?.includes("http") ? store?.buyerDomain : "https://" + store?.buyerDomain} />} tilte='Domain chính mà shop đang dùng'></CardDetailInfo></div>
        <div><CardDetailInfo name='Link quản trị' value={<Link to={store?.sellerDomain} />} tilte='Link myHaravan của shop'></CardDetailInfo></div>
        <div><CardDetailInfo name='Tên chủ shop' value={`${store?.ownerFirstName ?? ''} ${store?.ownerLastName ?? ''}`} tilte='Tên owner shop'></CardDetailInfo></div>
        <div><CardDetailInfo name='Owner email' value={<Email to={store?.ownerUserEmail} />} tilte='Email contact của chủ shop, lúc đăng ký, cập nhật khi shop thay đổi owner'></CardDetailInfo></div>
        <div><CardPhoneDetailInfo name='Điện thoại' phoneVerifired={store?.phone_verified} value={store?.phone} tilte='Số điện thoại owner shop, lúc đăng ký'></CardPhoneDetailInfo></div>
        <div><CardAddressShop name='Địa chỉ' value={store?.address} tilte='Địa chỉ của shop lúc đăng ký, cập nhật khi thay đổi địa chỉ ở setting > general'></CardAddressShop></div>
      </div>
    );
  };
  const renderHaravanRate = () => {
    return (
      <div>
        <div><CardDetailInfo name='Hạng thành viên' value={store?.membership} tilte='Hạng thành viên'></CardDetailInfo></div>
        <div><CardDetailInfo name='Ngày hết hạn' value={toDateTimeString(store?.membershipExpiredDate)} tilte='Ngày hết hạn'></CardDetailInfo></div>
      </div>
    );
  };

  const renderRegistrationInformation = () => {
    return (
      <div>
        <div><CardDetailInfo name='Ngày đăng ký' value={toDateTimeString(store?.storeCreatedDate)} tilte='Ngày shop đăng ký'></CardDetailInfo></div>
        <div><CardDetailInfo name='Affiliate' value={store?.partner?.ref ? <Detail to={`/partners/${store?.partner?.id}`}>{store?.ref}</Detail> : store?.ref} tilte='Thông tin affliate, cho biết shop được đăng ký ref nào'></CardDetailInfo></div>
        <div><CardDetailInfo name='HChan' value={store?.hChan} tilte='HChan'></CardDetailInfo></div>
        <div><CardDetailInfo name='Referrer' value={store?.referrer} tilte='Referrer'></CardDetailInfo></div>
        <div><CardDetailInfo name='ReferrerSite' value={store?.referringSite} tilte='ReferrerSite'></CardDetailInfo></div>
        <div><CardDetailInfo name='LandingSite' value={store?.landingSite} tilte='LandingSite'></CardDetailInfo></div>
        <div><CardDetailInfo name='LandingSiteRef' value={store?.landingSiteRef} tilte='LandingSiteRef'></CardDetailInfo></div>
        <div><CardDetailInfo name='Haravan Utm' value={store?.haravanUTM} tilte='Haravan Utm'></CardDetailInfo></div>
        <div><CardDetailInfo name='UtmSource' value={store?.utmSource} tilte='Thông tin UTM'></CardDetailInfo></div>
        <div><CardDetailInfo name='UtmMedium' value={store?.utmMedium} tilte='Thông tin UTM'></CardDetailInfo></div>
        <div><CardDetailInfo name='UtmCampaign' value={store?.utmCampaign} tilte='Thông tin UTM'></CardDetailInfo></div>
        <div><CardDetailInfo name='UtmTerm' value={store?.utmTerm} tilte='Thông tin UTM'></CardDetailInfo></div>
        <div><CardDetailInfo name='UtmContent' value={store?.utmContent} tilte='Thông tin UTM'></CardDetailInfo></div>
      </div>
    );
  };

  const renderServiceInformation = () => {
    return (
      <div>
        <div><CardDetailInfo name='Gói dịch vụ website' value={`${store?.shopPlan?.name}`} tilte='Gói dịch vụ mà shop đang dùng'></CardDetailInfo></div>
        <div><CardDetailInfo name='Hạn hợp đồng website' value={toDateTimeString(store?.actualExpiryDate)} tilte='Thời gian hết hạn của gói dịch vụ shop đang dùng'></CardDetailInfo></div>
        <div><CardDetailInfo name='PlanCode' value={store?.planCode} tilte='Mã gói dịch vụ mà shop đang dùng'></CardDetailInfo></div>
        <div><CardDetailInfo name='RemainDays' value={store?.remainDays} tilte='Số ngày còn lại của gói dịch vụ shop đang dùng'></CardDetailInfo></div>
        <div><CardDetailInfo name='RemainValue' value={<CurrencyTransaction ammount={store?.remainValue} />} tilte='Giá trị còn lại của gói giá dịch vụ đang dùng'></CardDetailInfo></div>
        <div><CardDetailInfo name='Tình trạng website' value={store?.actualExpiryDate > timeNow ? <Status text={"Đang hoạt động"} /> :
          <Status text={"Ngưng hoạt động"} />} tilte='Website đang còn hoạt động hay đã ngưng sử dụng'></CardDetailInfo></div>
        <div><CardDetailInfo name='Trạng thái ShopPlan' value={<Status text={store?.shopPlanStatus} />} tilte='Trạng thái ShopPlan'></CardDetailInfo></div>
      </div>
    );
  };

  const renderBusinessInformation = () => {
    return (
      <div>
        <div><CardDetailInfo name='Loại hình doanh nghiệp' value={store?.verified_business_type} tilte='Loại hình doanh nghiệp khi chủ shop đăng ký shop'></CardDetailInfo></div>
        <div><CardDetailInfo name='Mô hình kinh doanh' value={store?.verified_business_model_name} tilte='Mô hình kinh doanh của shop'></CardDetailInfo></div>
        <div><CardDetailInfo name='Nghành hàng' value={T(store?.verified_industry_type_name)} tilte='Ngành hàng kinh doanh của shop'></CardDetailInfo></div>
        <div><CardDetailInfo name='Tên doanh nghiệp(XHĐ)' value={store?.verified_name} tilte='Thông tin tên doanh nghiệp để xuất hoá đơn'></CardDetailInfo></div>
        <div><CardDetailInfo name='Mã số thuế' value={store?.verified_vat_tax_code} tilte='Thông tin mã số thuế doanh ngiệp'></CardDetailInfo></div>
        <div><CardDetailInfo name='Địa chỉ đăng ký kinh doanh' value={store?.verified_address} tilte='Thông tin địa chỉ doanh nghiệp'></CardDetailInfo></div>
        <div><CardDetailInfo name='Email(XHĐ)' value={<Email to={store?.verified_email} />} tilte='Email dùng để nhận hoá đơn'></CardDetailInfo></div>
        <div><CardDetailInfo name='Số điện thoại(Verified)' value={store?.phoneStoreInfo} tilte='Số điện thoại xác thực của doanh nghiệp'></CardDetailInfo></div>
        <div><CardDetailInfo name='Tags' value={store?.tags} tilte='Tags'></CardDetailInfo></div>
      </div>
    );
  };

  const expand = (keys) => {
    setActiveKeys(keys);
  }
  const chooseIcon = ({ isActive }) => isActive ? iconMinus : iconPlus;

  const iconPlus = <HrvComponents.Icon type='plusCircle' theme='solid' />;
  const iconMinus = <HrvComponents.Icon type='minusCircle' theme='solid' />;

  const renderBody = () => {
    return isLoadingData ? (
      <HrvComponents.Loading className='hrv-loading-page' size='thumb' />
    ) : (
      <HrvComponents.Collapse expandIcon={chooseIcon} onChange={expand} defaultActiveKey='1'>
        <HrvComponents.Collapse.Panel header="Thông tin Website" key="1">
          {renderShopInformation()}
        </HrvComponents.Collapse.Panel>
        <HrvComponents.Collapse.Panel header="Haravan Rate" key="5">
          {renderHaravanRate()}
        </HrvComponents.Collapse.Panel>
        <HrvComponents.Collapse.Panel header="Thông tin đăng ký" key="2">
          {renderRegistrationInformation()}
        </HrvComponents.Collapse.Panel>
        <HrvComponents.Collapse.Panel header="Thông tin gói dịch vụ" key="3">
          {renderServiceInformation()}
        </HrvComponents.Collapse.Panel>
        <HrvComponents.Collapse.Panel header="Thông tin doanh nghiệp" key="4">
          {renderBusinessInformation()}
        </HrvComponents.Collapse.Panel>
      </HrvComponents.Collapse>
    )
  }

  return (
    <div >
      {renderBody()}
    </div>
  )
}
