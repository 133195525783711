import * as ApiHelpers from './api_helper'
const apiRoute = 'roles'
const apiFunctionRoute = 'functions';

export const PermissionRepository = {
    GetRolesForRequestAccess: () => ApiHelpers.api_call_get(`${apiRoute}/request_access`),
    GetListRole: (flow) => ApiHelpers.api_call_get(`${apiRoute}${flow ? `?flow=${flow}` : ''}`),
    GetDetail: (id) => ApiHelpers.api_call_get(`${apiRoute}/${id}`),
    PutRole: data => ApiHelpers.api_call_put(apiRoute, data),
    AddRole: data => ApiHelpers.api_call_post(`${apiRoute}/role`,data),
    DeleteRole: (id) => ApiHelpers.api_call_delete(`${apiRoute}/${id}`),
    GetListUserByRoleId: (id) => ApiHelpers.api_call_get(`${apiRoute}/users/${id}`),
    DeleteUserRole:(roleId, userId) => ApiHelpers.api_call_delete(`${apiRoute}/users/${roleId}?ssoUserId=${userId}`),
    GetAllFunctions: (flow) => ApiHelpers.api_call_get(`${apiRoute}/${apiFunctionRoute}/all${flow ? `?flow=${flow}` : ''}`),
    DeleteFunction: (id) => ApiHelpers.api_call_delete(`${apiRoute}/${apiFunctionRoute}/${id}`),
    UpdateFunction: (data) => ApiHelpers.api_call_put(`${apiRoute}/${apiFunctionRoute}`, data)
  }