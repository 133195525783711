import { DataAction, DataList, DataSearch, Detail, Email } from '../../../components'
import { PartnerRepository, UserRepository } from '../../../repositories'
import { toDateTimeString } from '../../../global/utils/functions'
import { Notification } from '@haravan/react-components'
import { downloadLink } from '../../../global/utils/file_helper'

import './index.css'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../auth/services/auth_slice'

const headers = [
  { name: 'Họ tên', className: 'partner-name' },
  { name: 'Ref', className: 'partner-ref' },
  { name: '% shopplan', className: 'partner-percent-shoplan' },
  { name: '% app', className: 'partner-percent-app' },
  { name: '% theme', className: 'partner-percent-theme' },
  { name: 'Email', className: 'partner-email' },
  { name: 'Ngày đăng ký', className: 'partner-sign-date' },
]

export const PartnerList = () => {

  const fetchPartners = async (page, pageSize, search, filter) => {
    const response = await PartnerRepository.GetListPartner(page, pageSize, search)
    const list = response?.data?.data?.map(p => ({
      fullName: <Detail to={`/partners/${p.id}`}>{`${p.lastName} ${p.firstName}`}</Detail>,
      ref: p.ref,
      percentShopplan: `${p.payment_SharePercent_ShopPlan}%`,
      percentApp: `${p.payment_SharePercent_App}%`,
      percentTheme: `${p.payment_SharePercent_Theme}%`,
      email: <Email to={p.userName} />,
      signDate: toDateTimeString(p.createdDate),
    }))
    const rawData = response?.data?.data
    const total = response?.data?.totalRecord
    return { list, rawData, total }
  }

  const [canRequestAccess, setCanRequestAccess] = useState(false)
  const user = useSelector(selectUser)

  const exportData = async (checkedValues, search) => {
    var data = { listIds: checkedValues, textSearch: search }
    var response = await PartnerRepository.Export(data)
    if (response?.error) {
      Notification.error({ message: response.message, duration: 2 })
    } else if (response?.data) {
      downloadLink(response?.data?.url)
    }
  }

  useEffect(() => {
    user &&
      UserRepository.GetFunctions(0, user.profile?.sub).then(rs => {
        setCanRequestAccess(rs?.data?.findIndex(r => r.code === 'inside.partner.export') > -1)
      })
  }, [user])

  return (
    <div className='partner-list-wrapper'>
      <div className='flex stretch mb-md'>
        <DataSearch usedBy='partner-list' placeholder='Tìm kiếm theo Id, ref, email, họ tên của khách hàng' />
        { canRequestAccess && <DataAction usedBy='partner-list' onExport={exportData} />}
      </div>
      <DataList usedBy='partner-list' headers={headers} loadData={fetchPartners} />
    </div>
  )
}
