import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Input, Notification } from '@haravan/react-components'
import { TreeView } from '../../../../components'
import { UserRepository } from '../../../../repositories'
import { closePopup } from '../../../../components/popup/popup_slice'

import './index.css'

export function Permission({ id, userName }) {
  const dispatch = useDispatch()

  const [user, setUser] = useState({ id, userName })
  const [roles, setRoles] = useState([])
  const [selectedRoles, setSelectedRoles] = useState([])

  useEffect(() => {
    UserRepository.GetRoles().then(rs => {
      const roleData = rs?.data?.map(r => ({ value: r.id, label: r.name })) //, children: []
      setRoles(roleData)
      UserRepository.GetRoles(id).then(rs => {
        const selectedRoleData = rs?.data?.map(r => r.id) //, children: []
        setSelectedRoles(selectedRoleData)
      })
    })
  }, [])

  const save = () => {
    var data = { roleIds: selectedRoles.map(r => parseInt(r)), SSOUserId: id }
    UserRepository.SetRolesForUser(id, data)
      .then(rs => {
        if (rs?.data && !rs?.has_error) {
          Notification.success({ message: 'Lưu thành công', duration: 2 })
          dispatch(closePopup())
        }
      })
  }

  return (
    <div className='permission-user'>
      <div>
        <div className='flex v-center'>
          <div className='email'>Email: </div>
          <Input readOnly value={user.userName} />
        </div>
        <div className='flex'>
          <div className='roles'>Quyền: </div>
          {roles && <TreeView data={roles} selectedData={selectedRoles} onCheckChanged={setSelectedRoles}/>}
        </div>
      </div>

      <div className='permission-user-btn'>
        <Button status='default' onClick={() => dispatch(closePopup())}>
          Hủy
        </Button>
        <Button status='primary' onClick={save} disabled={!roles.length}>
          Lưu
        </Button>
      </div>
    </div>
  )
}
