import { StoreRepository } from '../../../../../repositories'
import { toDateTimeAllString, toDateTimeString } from '../../../../../global/utils/functions'
import { useParams } from 'react-router-dom'
import { Collapse, Icon } from '@haravan/react-components'
import { Currency, Detail, Link, ListViewSimplePaging, Status, DataList } from '../../../../../components'
import { ListViewHeader, ListViewPageSize, ListViewPagingInfo } from '../../../../../components/list_view/listview_simple_paging'
import { useLocation } from 'react-router-dom';
import { DetailTransaction } from '../../../../../components/link/link'
import { FormatProductName } from '../../../../../components/format/format'

import './index.css'
import { useState } from 'react'

const headers = [
  { name: 'Mã giao dịch', className: 'shop-transaction-id' },
  { name: 'Đơn hàng', className: 'shop-transaction-id' },
  { name: 'Ngày phát sinh', className: 'shop-transaction-created-date' },
  { name: 'Loại giao dịch', className: 'shop-transaction-type' },
  { name: 'Tên sản phẩm', className: 'shop-transaction-product' },
  { name: 'Giá trị', className: 'shop-transaction-totalAmount' },
  { name: 'Trạng thái', className: 'shop-transaction-status' },
  { name: 'Đối tác', className: 'shop-transaction-partner-name' },
] as ListViewHeader[]

const headersShopPlan = [
  { name: 'Đơn hàng', className: 'shop-transaction-id' },
  { name: 'Plan Code', className: 'shop-transaction-partner-name' },
  { name: 'Plan Status', className: 'shop-transaction-type' },
  { name: 'Ngày hết hạn', className: 'shop-transaction-created-date' },
  { name: 'Ngày ghi nhận', className: 'shop-transaction-created-date' },
  { name: 'Trạng thái', className: 'shop-transaction-status' },
  { name: 'Loại', className: 'shop-transaction-partner-name' },
] as ListViewHeader[]
const chooseIcon = ({ isActive }) => isActive ? <Icon type='minusCircle' theme='solid' /> : <Icon type='plusCircle' theme='solid' />;

export const ShopTransactionList = props => {
  const { pathname } = useLocation()
  const { shopId } = useParams()
  const [shopName, setShopName] = useState(null);
  const [activeKeys, setActiveKeys] = useState([])

  const getData = (paging: ListViewPagingInfo, id) => StoreRepository.GetTransactionsByShopId(shopId, paging.searchText, paging.before, paging.after, paging.limit)
  const fetchHistoryStripeCustomer = async (page, pageSize, search, filter) => {
    const response = await StoreRepository.GetCustomersHistoryByShopId(shopId, page, pageSize);
    let list = [];
    response?.data?.data?.map((obj) => {
      let displayOrderId = true;
      obj?.histories?.map((histories, _index) => {
        let _constStatus = "";
        let _constOrderId = histories[0].orderId;
        histories.map((his, index) => {
          if (index > 0) displayOrderId = false;
          if (_index > 0 && _constOrderId == his.orderId) displayOrderId = false;
          let cssItemNexttStatus = _constStatus != his.status || _constOrderId != his.orderId;
          _constStatus = his.status;
          _constOrderId = his.orderId;
          let urlSeller = process.env.REACT_APP_ADMIN_SHOPPLANS + "/" + his.orderId;
         
          let data = {
            orderId: obj.orderId <= 0 ? <p className={displayOrderId && cssItemNexttStatus && 'itemNext'}>{displayOrderId && <span>Manual</span>}</p> : <p className={displayOrderId && cssItemNexttStatus && 'itemNext'}>{displayOrderId && <a href={urlSeller} target="_blank">{obj.orderId}</a>}</p>,
            planCode: <p className={cssItemNexttStatus && 'itemNext'}>{his.planCode}</p>,
            planStatus: his.planStatus && <p className={cssItemNexttStatus && 'itemNext'}><Status text={his.planStatus} /></p>,
            expiredDate: <p className={cssItemNexttStatus && 'itemNext'}>{toDateTimeAllString(his.expiredDate)}</p>,
            updatedDate: <p className={cssItemNexttStatus && 'itemNext'}>{toDateTimeAllString(his.updatedDate)}</p>,
            status: his.status && <p className={cssItemNexttStatus && 'itemNext'}><Status text={his.status} /></p>,
            group: <p className={cssItemNexttStatus && 'itemNext'}>{his.group}</p>,
          }
          list.push(data);
        });
      });
    });
    const rawData = response?.data?.data
    const total = response?.data?.totalRecord
    return { list, rawData, total }
  };

  const message = shopName != null ? 'Tìm kiếm theo id, order id cửa hàng ' + shopName : 'Tìm kiếm theo id, order id ';

  const renderRow = (transaction, index) => {
    if (!shopName) setShopName(transaction?.shop?.name.toUpperCase());
    let transactionPath = `/transactions/${transaction?.id}`;
    let partnerPath = `/partners/${transaction?.partner?.id}`;
    let urlSeller;
    if (transaction?.type != null) {
      if (transaction?.isShopPlan) {
        urlSeller = process.env.REACT_APP_ADMIN_SHOPPLANS + "/" + transaction?.orderId;
      }
      else {
        if (transaction.type == "App") {
          urlSeller = process.env.REACT_APP_ADMIN_APPS + "/" + transaction?.orderId
        }
        if (transaction.type == "Theme") {
          urlSeller = process.env.REACT_APP_ADMIN_THEMES + "/" + transaction?.orderId
        }
        if (transaction.type != "App" && transaction.type != "Theme") {
          urlSeller = process.env.REACT_APP_ADMIN_SHOPPLANS + "/" + transaction?.orderId
        }
      }
    }
    return (
      <>
        <div className='shop-transaction-id'>
          <a href={transactionPath} target="_blank"><DetailTransaction>{`${transaction?.id}`}</DetailTransaction></a>
        </div>
        <div className='shop-transaction-id'>
          <a href={urlSeller} target="_blank">{transaction?.orderId}</a>
        </div>
        <div className='shop-transaction-created-date'>{toDateTimeString(transaction.createdDate)}</div>
        <div className='shop-transaction-type'>
          <p>{transaction.type}</p>
        </div>
        <div className='shop-transaction-product'>
          <p>{FormatProductName(transaction.shopPlan?.name ?? transaction.app?.name ?? transaction.theme?.name ?? '')}</p>
        </div>
        <div className='shop-transaction-totalAmount'>
          <Currency ammount={transaction.totalAmount} />
        </div>
        <div className='shop-transaction-status'>
          <Status text={transaction.status == "Đã thanh toán" ? "Paid" : transaction.status == "Đã hủy" ? "Canceled": ""} />
        </div>
        <div className='shop-transaction-partner-name'>
          <Detail to={partnerPath}>{`${transaction?.partner?.lastName ?? ''} ${transaction?.partner?.firstName ?? ''}`}</Detail>
        </div>
      </>
    )
  }

  return (
    <Collapse expandIcon={chooseIcon} onChange={(keys) => setActiveKeys(keys as any[])} defaultActiveKey='1'>
      <Collapse.Panel header="Lịch sử thanh toán" key="1">
        <ListViewSimplePaging
          allowSearch
          searchPlaceHolder={message}
          className='theme-file-border'
          getData={paging => getData(paging, shopId)}
          pageSize={ListViewPageSize.MEDIUM_PAGE_SIZE}
          renderRow={renderRow}
          headers={headers}
        />
      </Collapse.Panel>
      <Collapse.Panel header="Lịch sử gói" key="2">
        <DataList headers={headersShopPlan} loadData={fetchHistoryStripeCustomer} header_normal={true} />
      </Collapse.Panel>
    </Collapse>
  )
}