import * as ApiHelpers from './api_helper';

const apiUserRoute = 'users';
const apiRoleRoute = 'roles';
const apiFunctionRoute = 'functions';

export const UserRepository = {
  GetUser: id => ApiHelpers.api_call_get(`${apiUserRoute}/${id}`),
  GetUsers: (page = 1, pageSize = 20, search = '') => {
    let path = ApiHelpers.GetApiUrl(`${apiUserRoute}`, { page, pageSize, search });
    return ApiHelpers.api_call_get(path);
  },
  GetRoles: (id = 0) => {
    return id ? ApiHelpers.api_call_get(`${apiUserRoute}/${id}/roles`)
      : ApiHelpers.api_call_get(`${apiRoleRoute}`)
  },
  // GetRolesBySSUserId:(id) => ApiHelpers.api_call_get(`${apiRoleRoute}?SSOUserId=${id}`),
  GetRemainingRoles: (id, limit, textSearch) => ApiHelpers.api_call_get(`${apiRoleRoute}/remaining?userId=${id}&limit=${limit}&search=${textSearch}`),
  GetFunctions: (id, ssoId) => ApiHelpers.api_call_get(`${apiRoleRoute}/${apiFunctionRoute}?SSOUserId=${ssoId}`),
  GetAllFunctions: (flow) => ApiHelpers.api_call_get(`${apiRoleRoute}/${apiFunctionRoute}/all${flow ? `?flow=${flow}` : ''}`),
  UpdateUser: (id, data) => ApiHelpers.api_call_put(`${apiUserRoute}/${id}`, data),
  Export: data => ApiHelpers.api_call_post(`${apiUserRoute}/export`, data),
  SetRolesForUser: (id, data) => ApiHelpers.api_call_post(`${apiUserRoute}/${id}/roles`, data),
  DeleteFunction: (id) => ApiHelpers.api_call_delete(`${apiRoleRoute}/${apiFunctionRoute}/${id}`),
  UpdateFunction: (data) => ApiHelpers.api_call_put(`${apiRoleRoute}/${apiFunctionRoute}`, data)
};
