import * as ApiHelpers from './api_helper'

const apiRoute = 'shopplans'
const apiRouteProduct = 'products'

export const ShopplanRepository = {
  GetShopPlans: (search = '', limit = 10) => ApiHelpers.api_call_get(`${apiRoute}?search=${search}&limit=${limit}`),
  GetListShopPlans: (page = 1, pageSize = 20, search = '', productId = 0) => {
    let router = `${apiRoute}/list`
    let path = ApiHelpers.GetApiUrl(router, {
      page,
      pageSize,
      search, 
      productId
    })
    return ApiHelpers.api_call_get(path);
  },
  GetListProducts: (page = 1, pageSize = 20, search = '', id = 0) => {
    let router = `${apiRouteProduct}/list`
    let path = ApiHelpers.GetApiUrl(router, {
      page,
      pageSize,
      search, 
      id
    })
    return ApiHelpers.api_call_get(path);
  },
  GetAllShopPlansActived: (model) => ApiHelpers.api_call_post(`${apiRoute}/actived`, model),
  GetShopPlansById: (id, proId) => ApiHelpers.api_call_get(`${apiRouteProduct}/${proId}/${apiRoute}/${id}`),
  SetShopPlans: (model) => ApiHelpers.api_call_post(`${apiRoute}/set`, model),
  SetProducts: (model) => ApiHelpers.api_call_post(`${apiRouteProduct}/set`, model),
  GetProduct: id => ApiHelpers.api_call_get(`${apiRouteProduct}/${id}`)
}
