import * as ApiHelpers from './api_helper';

const apiRoute = 'transactions';

export const TransactionRepository = {
  GetListTransaction: (page = 1, pageSize = 20, search = '') => {
    let path = ApiHelpers.GetApiUrl(apiRoute, {
      page,
      pageSize,
      search
    })
    return ApiHelpers.api_call_get(path);
  },
  Filter: (model) => {
    return ApiHelpers.api_call_post(`${apiRoute}/filter`, model);
  },
  GetDetail: (id) => ApiHelpers.api_call_get(`${apiRoute}/${id}`),
  Export: (model) => {
    return ApiHelpers.api_call_post(`${apiRoute}/export`, model);
  },
  ElectronicBill: (model) => {
    return ApiHelpers.api_call_post(`${apiRoute}/electronic-bill`, model);
  },
  UpdateNote : (model) => ApiHelpers.api_call_put(`${apiRoute}/update-note`,model),
  UpdateInvoiceno : (model) => ApiHelpers.api_call_put(`${apiRoute}/update-invoiceno`,model),
  UpdateTransactionUser : (model) => ApiHelpers.api_call_put(`${apiRoute}/update-user`,model),
  GetUsers: (page = 1, pageSize = 20, search = '') => {
    let path = ApiHelpers.GetApiUrl(`${apiRoute}/list-users`, { page, pageSize, search });
    return ApiHelpers.api_call_get(path);
  },
};
