import * as HrvComponents from '@haravan/react-components';
import { StoreRepository } from '../../../../../repositories'
import { toDateTimeString } from '../../../../../global/utils/functions'
import { useParams } from 'react-router-dom'
import { Currency, Detail, DataList, DataSearch, Status } from '../../../../../components'
import { ListViewHeader } from '../../../../../components/list_view/listview_simple_paging'
import { Collapse, Icon, Loading } from '@haravan/react-components';
import * as React from 'react';

import './index.css'
import { CurrencyTransaction } from '../../../../../components/format/format';
import { useEffect, useState } from 'react';


const chooseIcon = ({ isActive }) => isActive ? <Icon type='minusCircle' theme='solid' /> : <Icon type='plusCircle' theme='solid' />;
const headers = [
  { name: 'Tên ứng dụng', className: 'app-name' },
  { name: 'Ngày cài app', className: 'install-date' },
  { name: 'Ngày gỡ app', className: 'remove-date' },
  { name: 'Trạng thái', className: 'appinstall-status' },
] as ListViewHeader[]

const headersShopPlan = [
  { name: 'Đơn hàng', className: 'shop-transaction-id' },
  { name: 'Plan Code', className: 'shop-transaction-partner-name' },
  { name: 'Plan Status', className: 'shop-transaction-type' },
  { name: 'Ngày hết hạn', className: 'shop-transaction-created-date' },
  { name: 'Ngày ghi nhận', className: 'shop-transaction-created-date' },
  { name: 'Trạng thái', className: 'shop-transaction-status' },
  { name: 'Loại', className: 'shop-transaction-partner-name' },
] as ListViewHeader[]

export const ShopAppInstallList = () => {
  const { shopId } = useParams()
  const [listApps, setListApps] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(false);

  useEffect(() => {
    fetchListAppInstalls();
  }, [])

  const fetchLogAppInstalls = async (page, pageSize, search) => {
    const response = await StoreRepository.GetLogAppInstallByShopId(shopId, page, pageSize, search);

    const list = response?.data?.data?.map(a => ({
      name: a.app && <Detail to={`/apps/${a.app?.id}`}>{a.app?.name}</Detail>,
      createTime: a.appEventId == 1 && toDateTimeString(a.createdDate),
      remove: a.appEventId == 2 && toDateTimeString(a.createdDate),
      status: a.appEventId == 1 ? <Status text="Cài app" /> : <Status text="Gỡ app" />
    }));
    const rawData = response?.data?.data
    const total = response?.data?.totalRecord
    return { list, rawData, total }
  };

  const fetchHistoryStripeCustomer = async (page, pageSize, search, filter) => {
    const response = await StoreRepository.GetCustomersHistoryByShopId(shopId, page, pageSize, "app");
    let list = [];
    response?.data?.data?.map((obj) => {
      let displayOrderId = true;
      obj?.histories?.map((histories, _index) => {
        let _constStatus = "";
        let _constOrderId = histories[0].orderId;

        let urlSeller = process.env.REACT_APP_ADMIN_APPS + "/" + _constOrderId;
        if (obj.isShopPlan) {
          urlSeller = process.env.REACT_APP_ADMIN_SHOPPLANS + "/" + _constOrderId
        }

        histories.map((his, index) => {
          if (index > 0) displayOrderId = false;
          if (_index > 0 && _constOrderId == his.orderId) displayOrderId = false;
          let cssItemNexttStatus = _constStatus != his.status || _constOrderId != his.orderId;
          _constStatus = his.status;
          _constOrderId = his.orderId;
          let data = {
            orderId: obj.orderId <= 0 ? <p className={displayOrderId && cssItemNexttStatus && 'itemNext'}>{displayOrderId && <span>Manual</span>}</p> : <p className={displayOrderId && cssItemNexttStatus && 'itemNext'}>{displayOrderId && <a href={urlSeller} target="_blank">{obj.orderId}</a>}</p>,
            planCode: <p className={cssItemNexttStatus && 'itemNext'}>{his.planCode}</p>,
            planStatus: his.planStatus && <p className={cssItemNexttStatus && 'itemNext'}><Status text={his.planStatus} /></p>,
            expiredDate: <p className={cssItemNexttStatus && 'itemNext'}>{toDateTimeString(his.expiredDate)}</p>,
            updatedDate: <p className={cssItemNexttStatus && 'itemNext'}>{toDateTimeString(his.updatedDate)}</p>,
            status: his.status && <p className={cssItemNexttStatus && 'itemNext'}><Status text={his.status} /></p>,
            group: <p className={cssItemNexttStatus && 'itemNext'}>{his.group}</p>,
          }
          list.push(data);
        });
      });
    });
    const rawData = response?.data?.data
    const total = response?.data?.totalRecord
    return { list, rawData, total }
  };

  const fetchListAppInstalls = async () => {
    setIsLoadingData(true);
    const response = await StoreRepository.GetListAppInstallByShopId(shopId);
    setListApps(response?.data?.data);
    setIsLoadingData(false);
  };

  const renderListApp = () => {
    return isLoadingData ? (
      <Loading className='hrv-loading-page' size='thumb' />
    ) : (<HrvComponents.Table
      renderTableHeader={
        <tr>
          <th className='app-name'>Tên ứng dụng</th>
          <th></th>
          <th className='expired-date'>Ngày hết hạn</th>
          <th className='expired-date'>Tháng</th>
          <th className='price-app'>Giá bán</th>
          <th className='partner-app'>Đối tác</th>
        </tr>
      }
      renderTableBody={
        listApps && listApps?.map((item) => {
          return <tr>
            <td><Detail to={`/apps/${item?.id}`}>{item?.appName ?? ""}</Detail></td>
            <td>{item.isTrial && <Status text="dùng thử" />}</td>
            <td>{toDateTimeString(item?.expiredDate)}</td>
            <td>{item.months}</td>
            <td><CurrencyTransaction ammount={item?.price} /></td>
            <td>{item?.partner && <Detail to={`/partners/${item?.partner?.id}`}>{`${item?.partner?.lastName} ${item?.partner?.firstName}`}</Detail>}</td>
          </tr>
        })
      }
    />);
  }

  return (
    <Collapse expandIcon={chooseIcon} defaultActiveKey='1'>
      <Collapse.Panel header="Danh sách ứng dụng" key="1">
        <div className='app-list-install-list'>
          {renderListApp()}
        </div>
      </Collapse.Panel>
      <Collapse.Panel header="Lịch sử cài" key="2">
        <div className='app-install-list'>
          <DataSearch className='mb-md' placeholder='Tìm kiếm theo tên app' />
          <DataList headers={headers} loadData={fetchLogAppInstalls} />
        </div>
      </Collapse.Panel>
      <Collapse.Panel header="Lịch sử gói" key="3">
        <DataList headers={headersShopPlan} loadData={fetchHistoryStripeCustomer} header_normal={true} />
      </Collapse.Panel>
    </Collapse>
  );
}