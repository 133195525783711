import * as ApiHelpers from './api_helper';

const apiRoute = 'partners';

export const PartnerRepository = {
  GetPartner: id => ApiHelpers.api_call_get(`${apiRoute}/${id}`),
  GetListPartner: async (page=1, pageSize=20, search='') => {
    let path = ApiHelpers.GetApiUrl(apiRoute, {
      page,
      pageSize,
      search
    })
    return ApiHelpers.api_call_get(path);
  },
  UpdatePartner: (id, data) => ApiHelpers.api_call_put(`${apiRoute}/${id}`, data),
  Export: data => ApiHelpers.api_call_post(`${apiRoute}/export`,data),
  GetAppByPartnerId: (id: number, query = '', before = 0, after = 0, limit = 20) => {
    let path = ApiHelpers.GetApiUrl(`${apiRoute}/${id}/app`, {
      query: query,
      before: before,
      after: after,
      limit: limit
    });
    return ApiHelpers.api_call_get(path);
  },
  GetThemeByPartnerId: (partnerId: number, query = '', before = 0, after = 0, limit = 20) => {
    let path = ApiHelpers.GetApiUrl(`${apiRoute}/${partnerId}/theme`, {
      query: query,
      before: before,
      after: after,
      limit: limit
    });
    return ApiHelpers.api_call_get(path);
  },
  GetPartnerBalanceByPartnerId: (partnerId: number, query = '', before = 0, after = 0, limit = 20) => {
    let path = ApiHelpers.GetApiUrl(`${apiRoute}/${partnerId}/partner-balance`, {
      query: query,
      before: before,
      after: after,
      limit: limit
    });
    return ApiHelpers.api_call_get(path);
  },
};
