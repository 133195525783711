import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toDateTimeString, IFilterType } from '../../../global/utils/functions'
import { StoreRepository, UserRepository } from '../../../repositories'
import { Notification } from '@haravan/react-components'
import { TableFilter, TableFilterHeader, Detail, Email, Link, Phone, FilterSearchModel, IRequestExportModel } from '../../../components'
import { selectUser } from '../../auth/services/auth_slice'
import './index.css'
import { DetailShop } from '../../../components/link/link'

const usedBy = 'shop-list'

export const ShopList = () => {
  const [canExport, setCanExport] = useState(false)
  const user = useSelector(selectUser)

  const fetchStores = async (filter: FilterSearchModel) => {
    const response = await StoreRepository.Filter(filter)
    const list = response?.data?.data?.map(s => ({
      storeId: <DetailShop to={`/shops/${s.id}`} isDeleted={s.isDeleted} className='text-left'>{`${s.id}`}</DetailShop>,
      storeName: s.isDeleted == true ? <div className='is-deleted'>{s.name}</div> : s.name,
      buyerDomain: s.isDeleted == true ? <div className='is-deleted'><Link to={s.buyerDomain} /></div> : <Link to={s.buyerDomain} />,
      shopPlan: s.isDeleted == true ? <div className='is-deleted'>{s.shopPlan?.name}</div> : s.shopPlan?.name,
      ownerEmail: s.isDeleted == true ? <div className='is-deleted'>{<Email to={s.ownerUserEmail} />}</div> : <Email to={s.ownerUserEmail} />,
      phone: s.isDeleted == true ? <div className='is-deleted'>{<Phone to={s.phone} />}</div> : <Phone to={s.phone} />,
      signDate: s.isDeleted == true ? <div className='is-deleted'>{toDateTimeString(s.storeCreatedDate)}</div> : toDateTimeString(s.storeCreatedDate),
      expireDate: s.isDeleted == true ? <div className='is-deleted'>{toDateTimeString(s.actualExpiryDate)}</div> : toDateTimeString(s.actualExpiryDate),
      TotalStore: s.isDeleted == true ? <div className='is-deleted'>{s.totalCurrentStore}</div> : s.totalCurrentStore,
      TotaUser: s.isDeleted == true ? <div className='is-deleted'>{s.totalCurrentUser}</div> : s.totalCurrentUser,
      TotalPage: s.isDeleted == true ? <div className='is-deleted'>{s.totalCurrentPage}</div> : s.totalCurrentPage,
    }))
    const rawData = response?.data?.data
    const total = response?.data?.totalRecord
    return { list, rawData, total }
  }
  const exportData = async (data: IRequestExportModel) => {
    var response = await StoreRepository.Export(data)
    if (!response.has_error)
      Notification.success({ message: 'Danh sách cửa hàng của bạn đã bắt đầu được trích xuất. Khi hoàn tất, chúng tôi sẽ gửi một email cùng với file đến cho bạn', duration: 2 })
  }
  useEffect(() => {
    user &&
      UserRepository.GetFunctions(0, user.profile?.sub).then(rs => {
        setCanExport(rs?.data?.findIndex(r => ['inside.admin', 'inside.shop.export'].includes(r.code)) > -1)
      })
  }, [user])


  const headers = [
    { name: 'Id', className: 'shop-id' },
    { name: 'Tên website', className: 'shop-name' },
    { name: 'Website', className: 'shop-ref' },
    {
      name: 'Shop plan',
      className: 'shop-percent',
    },
    { name: 'Owner email', className: 'shop-owner-email' },
    { name: 'Điện thoại', className: 'shop-phone' },
    { name: 'Ngày đăng ký', className: 'shop-sign-date' },
    { name: 'Ngày hết hạn', className: 'shop-expired-date' },
    { name: 'Số lượng chi nhánh', className: 'shop-total-store' },
    { name: 'Số lượng user', className: 'shop-total-user' },
    { name: 'Số lượng fanpage', className: 'shop-total-page' },
  ] as TableFilterHeader[]

  return (
    <div className='shop-list-wrapper'>
      <TableFilter type={IFilterType.shop} usedBy={usedBy} headers={headers} hiddenFilter={!canExport} exportData={canExport ? exportData : null} loadData={fetchStores} placeholder="Tìm kiếm theo tên cửa hàng, domain, email người dùng, số điện thoại" />
    </div>
  )

}
