import * as ApiHelpers from './api_helper';

const apiRoute = 'shops';

export const StoreRepository = {
  UpdateStore: (id, data) => ApiHelpers.api_call_put(`${apiRoute}/${id}`, data),
  GetStore: id => ApiHelpers.api_call_get(`${apiRoute}/${id}`),
  GetListStore: (page = 1, pageSize = 20, search = '') => {
    let path = ApiHelpers.GetApiUrl(apiRoute, {
      page,
      pageSize,
      search
    })
    return ApiHelpers.api_call_get(path);
  },
  Filter: (model) => {
    return ApiHelpers.api_call_post(`${apiRoute}/filter`, model);
  },
  Export: (model) => {
    return ApiHelpers.api_call_post(`${apiRoute}/export`, model);
  },
  AddAuthUser: (id, data) => ApiHelpers.api_call_post(`${apiRoute}/${id}/users`, data),
  UpdateAuthUser: (id, data) => ApiHelpers.api_call_put(`${apiRoute}/${id}/users`, data),
  GetTransactionsByShopId: (id, query = '', before = 0, after = 0, limit = 20) => {
    let path = ApiHelpers.GetApiUrl(`${apiRoute}/${id}/transactions`, {
      query: query,
      before: before,
      after: after,
      limit: limit
    });

    return ApiHelpers.api_call_get(path);
  },
  GetLogAppInstallByShopId: (id, page = 1, pageSize = 20, search = '') => {
    let path = ApiHelpers.GetApiUrl(`${apiRoute}/${id}/appinstall`, {
      page,
      pageSize,
      search
    });

    return ApiHelpers.api_call_get(path);
  },
  GetListAppInstallByShopId: (id) => {
    let path = (`${apiRoute}/${id}/applistinstall`);
    return ApiHelpers.api_call_get(path);
  },
  GetThemeInstallByShopId: (id, page = 1, pageSize = 20, search = '') => {
    let path = ApiHelpers.GetApiUrl(`${apiRoute}/${id}/themeinstall`, {
      page,
      pageSize,
      search
    });

    return ApiHelpers.api_call_get(path);
  },
  GetCustomersHistoryByShopId: (orgId, page = 1, pageSize = 10, prefix = "omni") => {
    let path = ApiHelpers.GetApiUrl(`${apiRoute}/${orgId}/customers/history`, {
      page: page,
      pageSize: pageSize,
      prefix: prefix
    });

    return ApiHelpers.api_call_get(path);
  },
  GetTiketsByShopId: (orgId, page = 1, pageSize = 10) => {
    let path = ApiHelpers.GetApiUrl(`${apiRoute}/${orgId}/tickets`, {
      page: page,
      pageSize: pageSize
    });

    return ApiHelpers.api_call_get(path);
  }
};
