import { Currency, DataList, Detail, Status, DataAction, DataSearch, DataFilterType, TableFilter, FilterSearchModel, IRequestExportModel } from "../../../components";
import { Notification } from "@haravan/react-components";
import { TransactionRepository, UserRepository } from "../../../repositories";
import { toDateTimeString, IFilterType } from "../../../global/utils/functions";

import "./index.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../auth/services/auth_slice";

const usedBy = 'transaction-list'

const transactionTypes = [
  { value: 100, children: 'Shopplan' },
  { value: 120, children: 'App' },
  { value: 110, children: 'Theme' },
]

const headers = [
  { name: "Giao dịch", className: "transaction-id" },
  { name: "Cửa hàng", className: "transaction-shop" },
  { name: "Loại giao dịch", className: "transaction-type" },
  { name: "Sản phẩm", className: "transaction-ref" },
  { name: "Đối tác", className: "transaction-partner" },
  { name: "Người ghi nhận", className: "transaction-saler" },
  { name: "Số tiền", className: "transaction-ammount" },
  { name: "Ngày tạo", className: "transaction-created-date" },
  { name: "Ngày thanh toán", className: "transaction-paid-date" },
  { name: "Phương thức", className: "transaction-method" },
  { name: "Trạng thái giao dịch", className: "transaction-status" },
];

export const TransactionList = () => {
  const fetchTransactions = async (filter: FilterSearchModel) => {
    const response = await TransactionRepository.Filter(filter);
    const rawData = response?.data?.data;
    const list = rawData?.map((t) => ({
      transactionId: <Detail to={`/transactions/${t.id}`}>{t.id}</Detail>,
      shopName: <Detail to={`/shops/${t.shop?.id}`}>{t.shop?.name || t.shop?.buyerDomain}</Detail>,
      transactionType: t.type,
      refName: t.shopPlan != null ? t.shopPlan?.name : <Detail to={`/${t.type.toLowerCase()}s/${t.productId ?? ""}`}>{t.productName ?? " "}</Detail>,
      partnerName: t.partner && <Detail to={`/partners/${t.partner.id}`}>{`${t.partner.lastName} ${t.partner.firstName}`.trim()}</Detail>,
      email: t.saleUser?.email,
      ammount: <Currency ammount={t.totalAmount} />,
      createdDate: toDateTimeString(t.createdDate),
      paymentDate: toDateTimeString(t.completeDate),
      paymentMethod: t.paymentMethodId,
      transactionStatus: <Status text={t.status} />,
    }));
    const total = response?.data?.totalRecord;
    return { rawData, list, total };
  };

  const exportData = async (data: IRequestExportModel) => {
    var response = await TransactionRepository.Export(data)
    if (!response.has_error)
      Notification.success({ message: 'Danh sách giao dịch của bạn đã bắt đầu được trích xuất. Khi hoàn tất, chúng tôi sẽ gửi một email cùng với file đến cho bạn', duration: 2 })
  }
  const electronicBillData = async (data: IRequestExportModel) => {
    var response = await TransactionRepository.ElectronicBill(data)
    if (!response.has_error)
      Notification.success({ message: 'Hóa đơn của bạn đã bắt đầu được trích xuất. Khi hoàn tất, chúng tôi sẽ gửi một email cùng với file đến cho bạn', duration: 2 })
  }

  const [canExport, setCanExport] = useState(false)
  const user = useSelector(selectUser)

  useEffect(() => {
    user &&
      UserRepository.GetFunctions(0, user.profile?.sub).then(rs => {
        setCanExport(rs?.data?.findIndex(r => ['inside.admin', 'inside.transaction.export'].includes(r.code)) > -1)
      })
  }, [user])

  return (
    <div className="transaction-list-wrapper">
      <TableFilter type={IFilterType.transactions} usedBy={usedBy} headers={headers} hiddenFilter={!canExport} exportData={canExport ? exportData : null} electronicBill={canExport ? electronicBillData : null} loadData={fetchTransactions} placeholder="Tìm kiếm theo id ,Order id,Store id của giao dịch" />
    </div>
  );
};
